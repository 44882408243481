import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from '../interfaces';
import { BackendService } from './backend.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private backendService: BackendService) {}

  public getUsers(
    search: string = '',
    page: number = 1,
    perPage: number = 15
  ): Observable<{ users: IUser[]; count: number }> {
    return this.backendService.get<{ users: IUser[]; count: number }>(
      'user/auth/users',
      {
        params: {
          s: search,
          per_page: `${perPage}`,
          page: `${page}`,
        },
      }
    );
  }

  public getUser(id: string): Observable<IUser> {
    return this.backendService.get<IUser>(`user/auth/users/${id}`);
  }

  public update(
    id: string,
    body: Partial<IUser & { oldPassword: string; newPassword: string }>
  ): Observable<IUser> {
    return this.backendService.put<IUser>(`user/auth/users/${id}`, body);
  }

  public register(body: {
    username: string;
    password?: string;
    company: string;
    permissions?: string[];
  }) {
    return this.backendService.post<IUser>(`user/auth/users`, body);
  }
}
