<ng-container>
  <div mat-dialog-title class="dialog-title-icon d-flex -col -left">
    <span
      ><mat-icon>vpn_key</mat-icon>
      {{ mode === "add" ? "Add a token" : "Edit a token" }}
    </span>
    <span *ngIf="mode === 'edit'" class="mat-h4">
      {{ token.token }}
    </span>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="form-field">
      <mat-label class="mat-h3">Status</mat-label>
      <mat-radio-group
        aria-label="Status of the token"
        [(ngModel)]="status"
        color="primary"
      >
        <mat-radio-button [value]="'active'">Active</mat-radio-button>
        <mat-radio-button [value]="'inactive'">Inactive</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-field">
      <mat-label class="mat-h3">Domains</mat-label>
      <mat-chip-list #domainList>
        <mat-chip
          *ngFor="let domain of domains; let i = index"
          [selectable]="true"
          [removable]="true"
          (removed)="removeDomain(i)"
        >
          {{ domain }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Token domains"
          [matChipInputFor]="domainList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodesDomains"
          (matChipInputTokenEnd)="addDomain($event)"
        />
      </mat-chip-list>
    </div>
    <div class="form-field">
      <mat-label class="mat-h3">Permissions</mat-label>
      <mat-chip-list #permissionList>
        <mat-chip
          *ngFor="let permission of selectedPermissions; let i = index"
          [selectable]="true"
          [removable]="true"
          (removed)="removePermission(i)"
        >
          {{ permission }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Token permissions"
          #permissionInput
          (input)="onPermissionInput($event.target)"
          [matAutocomplete]="autoPermissions"
          [matChipInputFor]="permissionList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodesPermissions"
          (matChipInputTokenEnd)="addPermission($event)"
        />
      </mat-chip-list>
      <mat-autocomplete
        #autoPermissions="matAutocomplete"
        (optionSelected)="selectPermission($event)"
      >
        <mat-option
          *ngFor="let permission of filteredPermissions | async"
          [value]="permission"
        >
          {{ permission }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      type="button"
      [disabled]="callSent"
      (click)="onCancel()"
      cdkFocusInitial
    >
      Cancel
    </button>
    <button mat-button type="submit" [disabled]="callSent" (click)="onSubmit()">
      {{ mode === "add" ? "Add token" : "Edit token" }}
    </button>
  </mat-dialog-actions>
</ng-container>
