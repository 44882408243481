import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TokenEditComponent } from '../../components/token-edit/token-edit.component';
import { IToken, IUser } from '../../interfaces';
import { AuthService } from '../../services/auth.service';
import { ResponsiveService } from '../../services/responsive.service';
import { TokenService } from '../../services/token.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.page.html',
  styleUrls: ['./user-detail.page.scss'],
})
export class UserDetailPage implements OnInit, OnDestroy {
  public permissionForm: FormGroup;
  public permissionCallSend: boolean = false;
  public permissionFormKeys: string[];
  public informationForm: FormGroup;
  public informationCallSend: boolean = false;

  public user: IUser;
  public tokens: IToken[];
  public isMobile = true;

  public loggedInRights: {
    changeRights: boolean;
    changeUser: boolean;
    generateTokens: boolean;
    editTokens: boolean;
    deleteTokens: boolean;
  } = {
    changeRights: false,
    changeUser: false,
    generateTokens: false,
    editTokens: false,
    deleteTokens: false,
  };
  private tokenPermissions: string[];

  private subscriptions: Subscription[] = [];

  @ViewChild('deleteModal', { static: true })
  private deleteModal: TemplateRef<any>;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private tokenService: TokenService,
    private responsiveService: ResponsiveService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    const userData$ = this.activatedRoute.data.pipe(
      map((data) => {
        const { user, tokens } = data;
        return { user, tokens };
      })
    );

    const loggedinData$ = this.authService.authorizedUser$;

    const initSub = combineLatest([
      userData$,
      loggedinData$,
      this.authService.allUserPermissions$,
      this.responsiveService.isMobile$,
      this.authService.allTokenPermissions$,
    ]).subscribe(
      ([
        { user, tokens },
        authorizedUser,
        allPermissions,
        isMobile,
        tokenPermissions,
      ]) => {
        this.isMobile = isMobile;
        this.user = user;
        this.tokens = tokens;
        this.tokenPermissions = [...tokenPermissions];

        const lowerCasedUserPermissions =
          authorizedUser?.permissions.map((p) => p.toLowerCase()) || [];

        this.loggedInRights.changeRights = lowerCasedUserPermissions.includes(
          'changeuserpermissions'
        );

        this.loggedInRights.changeUser = lowerCasedUserPermissions.includes(
          'changeuser'
        );

        this.loggedInRights.generateTokens = lowerCasedUserPermissions.includes(
          'generatetokens'
        );

        this.loggedInRights.editTokens = lowerCasedUserPermissions.includes(
          'edittokens'
        );

        this.loggedInRights.deleteTokens = lowerCasedUserPermissions.includes(
          'deletetokens'
        );

        if (this.loggedInRights.changeRights) {
          this.initPermissionForm(allPermissions, user);
        }

        if (this.loggedInRights.changeUser) {
          this.initInformationForm(user);
        }
      }
    );

    this.subscriptions.push(initSub);
  }

  // #region Tokens
  public addToken() {
    this.dialog
      .open(TokenEditComponent, {
        width: this.isMobile ? '75vw' : '50vw',
        data: {
          user: this.user._id,
          tokenPermissions: this.tokenPermissions,
        },
      })
      .afterClosed()
      .subscribe((result: IToken) => {
        if (result) {
          this.tokens = [...this.tokens, result];
        }
      });
  }

  public editToken(token: IToken) {
    this.dialog
      .open(TokenEditComponent, {
        width: this.isMobile ? '75vw' : '50vw',
        data: {
          token: { ...token },
          user: this.user._id,
          tokenPermissions: this.tokenPermissions,
        },
      })
      .afterClosed()
      .subscribe((result: IToken) => {
        if (result) {
          this.tokens = [
            ...this.tokens.map((t) => {
              if (t._id === result._id) {
                return result;
              }
              return t;
            }),
          ];
        }
      });
  }

  public deleteToken(token: IToken) {
    this.dialog
      .open(this.deleteModal, {
        width: this.isMobile ? '75vw' : '50vw',
        data: {
          token: token.token,
          domains: token.domains,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result: boolean) => {
          if (result) {
            return this.tokenService.deleteToken(this.user._id, token._id);
          }
          return of(undefined);
        })
      )
      .subscribe(
        (result) => {
          if (result) {
            this.tokens = [...this.tokens.filter((t) => t._id !== token._id)];
          }
        },
        () => {
          this.snackBar.open('Something went wrong...', 'OK', {
            panelClass: ['error'],
            verticalPosition: 'top',
          });
        }
      );
  }
  // #endregion

  // #region Permissions
  private initPermissionForm(
    allPermissions: string[],
    user: IUser,
    reset = false
  ) {
    if (this.permissionForm && !reset) {
      return;
    }
    this.permissionFormKeys = [];

    const lowerCasedUserPermissions = user.permissions.map((p) =>
      p.toLowerCase()
    );

    const form: { [key: string]: FormControl } = {};

    allPermissions.forEach((permission) => {
      form[permission] = new FormControl(
        lowerCasedUserPermissions.includes(permission.toLowerCase())
      );
      this.permissionFormKeys.push(permission);
    });

    this.permissionForm = new FormGroup(form);
  }

  onSubmitPermissionForm() {
    if (!this.permissionForm.value || this.permissionForm.invalid) {
      return;
    }

    const newPermissions: string[] = Object.keys(
      this.permissionForm.value
    ).filter((key) => !!this.permissionForm.value[key]);

    this.permissionCallSend = true;

    this.userService
      .update(this.user._id, { permissions: newPermissions })
      .subscribe(
        (user) => {
          this.user = user;
          this.permissionCallSend = false;
        },
        (error) => {
          this.permissionCallSend = false;
        }
      );
  }
  // #endregion

  // #region Information
  private initInformationForm(user: IUser) {
    this.informationForm = new FormGroup(
      {
        id: new FormControl({ value: user._id, disabled: true }),
        username: new FormControl(user.username, [
          Validators.required,
          Validators.email,
        ]),
        company: new FormControl(user.company, [Validators.required]),
        oldPassword: new FormControl(undefined),
        newPassword: new FormControl(undefined),
      },
      (group: AbstractControl) => {
        const oldPasswordInput = (group as FormGroup).controls['oldPassword'];
        const newPasswordInput = (group as FormGroup).controls['newPassword'];

        if (!oldPasswordInput.value && !newPasswordInput.value) {
          return null;
        }

        if (oldPasswordInput.value && newPasswordInput.value) {
          return null;
        }

        if (oldPasswordInput.untouched || newPasswordInput.untouched) {
          return null;
        }

        return {
          bothPasswordFieldRequired: true,
        };
      }
    );
  }

  onSubmitInformationForm() {
    this.informationForm.markAllAsTouched();
    this.informationForm.updateValueAndValidity();
    if (!this.informationForm.value || this.informationForm.invalid) {
      return;
    }

    const {
      value: { company, oldPassword, newPassword, username },
    } = this.informationForm;

    if (!company) {
      return;
    }

    const body: Partial<IUser> &
      Partial<{ newPassword: string; oldPassword: string }> = {
      company,
      username,
    };

    let passwordChange = false;

    if (oldPassword || newPassword) {
      // at least 1 is filled. Check if both are filled
      if (!(oldPassword && newPassword)) {
        // Only 1 is filled
        return;
      }

      passwordChange = true;
      body.newPassword = newPassword;
      body.oldPassword = oldPassword;
    }

    this.informationCallSend = true;
    this.userService.update(this.user._id, body).subscribe(
      (user) => {
        this.user = user;
        this.permissionCallSend = false;
      },
      (error) => {
        if (passwordChange && error.status === 403) {
          this.snackBar.open('Incorrect password', 'OK', {
            panelClass: ['error'],
            verticalPosition: 'top',
          });
        }
        if (error.status === 409) {
          this.snackBar.open('Email already in use', 'OK', {
            panelClass: ['error'],
            verticalPosition: 'top',
          });
        }

        this.permissionCallSend = false;
      }
    );
  }
  // #endregion

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
