<div class="login-page">
  <mat-card>
    <mat-card-content>
      <img class="logo" src="assets/logo.png" alt="CampaignSuite" />
      <button
        mat-icon-button
        class="settings-button"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="legacy">
          <mat-label for="username-field">Username</mat-label>
          <input
            matInput
            name="username"
            id="username-field"
            placeholder="info@campaignsuite.com"
            formControlName="username"
            required
            type="email"
          />
        </mat-form-field>

        <mat-form-field appearance="legacy">
          <mat-label for="password-field">Password</mat-label>
          <input
            matInput
            name="password"
            placeholder="password"
            formControlName="password"
            required
            type="password"
          />
        </mat-form-field>
        <ng-container *ngIf="showError">
          <mat-error> The filled in credentials are not correct </mat-error>
        </ng-container>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="isLoading || form.invalid"
        >
          Log in
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openAdvanced()">
    <mat-icon>settings</mat-icon>
    <span>Advanced Settings</span>
  </button>
</mat-menu>

<ng-template #advancedModal>
  <h1 mat-dialog-title>Advanced settings</h1>
  <div mat-dialog-content class="advanced-settings">
    <mat-form-field>
      <mat-label>API Base URL</mat-label>
      <input matInput #baseURLInput />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="cancelAdvanced()">Cancel</button
    ><span class="spacer"></span>
    <button mat-button cdkFocusInitial (click)="saveAdvanced()">Ok</button>
  </div>
</ng-template>
