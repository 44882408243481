import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { IToken } from 'src/app/interfaces';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-token-edit',
  templateUrl: './token-edit.component.html',
  styleUrls: ['./token-edit.component.scss'],
})
export class TokenEditComponent {
  public token: IToken;
  private user: string;
  public mode: 'add' | 'edit' = 'add';

  public status: 'active' | 'inactive' = 'inactive';

  public domains: string[] = [];

  private tokenPermissions: string[] = [];

  public callSent = false;

  public separatorKeysCodesPermissions: number[] = [ENTER, COMMA];
  public separatorKeysCodesDomains: number[] = [ENTER, COMMA, SPACE];

  @ViewChild('permissionInput', { static: false })
  permissionInput: ElementRef<HTMLInputElement>;
  public selectedPermissions: string[] = [];
  public filteredPermissions = new BehaviorSubject<string[]>(
    this.tokenPermissions
  );

  constructor(
    public dialogRef: MatDialogRef<TokenEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { token: IToken; user: string; tokenPermissions: string[] },
    private tokenService: TokenService,
    private snackBar: MatSnackBar
  ) {
    this.token = data.token;
    this.mode = !this.token ? 'add' : 'edit';
    this.user = data.user;

    if (data.tokenPermissions) {
      this.tokenPermissions = data.tokenPermissions;
    }

    if (this.token) {
      this.domains = [...this.token.domains];
      this.selectedPermissions = [...this.token.permissions];
      this.status = this.token.status;
    }
  }

  onSubmit() {
    this.callSent = true;

    let obs: Observable<IToken>;

    if (this.mode === 'add') {
      obs = this.addToken(this.status, this.domains, this.selectedPermissions);
    } else {
      obs = this.editToken(this.status, this.domains, this.selectedPermissions);
    }

    return obs.subscribe(
      (token: IToken) => {
        this.callSent = false;
        const message = this.mode === 'add' ? 'Token created' : 'Token updated';
        this.snackBar.open(message, 'OK', {
          panelClass: ['success'],
          verticalPosition: 'top',
        });
        this.dialogRef.close(token);
      },
      (error: any) => {
        let message = 'Something went wrong...';
        if (error.status === 403) {
          message = 'Not authorized';
        }

        if (error.status === 426) {
          message = 'Limit of tokens reached';
        }

        this.snackBar.open(message, 'OK', {
          panelClass: ['error'],
          verticalPosition: 'top',
        });
        this.callSent = false;
      }
    );
  }

  private addToken(
    status: 'active' | 'inactive',
    domains: string[],
    permissions: string[]
  ) {
    return this.tokenService.createToken(this.user, {
      domains,
      permissions,
      status,
    });
  }

  private editToken(
    status: 'active' | 'inactive',
    domains: string[],
    permissions: string[]
  ) {
    return this.tokenService.updateToken(this.user, this.token._id, {
      domains,
      permissions,
      status,
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  // #region Chips Domains
  removeDomain(index: number): void {
    if (index < 0) {
      return;
    }

    this.domains.splice(index, 1);
  }

  addDomain(event: MatChipInputEvent) {
    const { input, value } = event;

    if (!(value || '').trim()) {
      return;
    }

    if (this.domains.indexOf(value.trim()) >= 0) {
      return;
    }

    this.domains.push(value.trim());

    if (input) {
      input.value = '';
    }
  }
  // #endregion

  // #region Chips Permissions
  // Has to be for the event in the HTML :/
  onPermissionInput(target: any) {
    this.filteredPermissions.next(
      this.tokenPermissions
        .filter(
          (permission) => permission.toLowerCase().indexOf(target.value) === 0
        )
        .filter(
          (permission) => this.selectedPermissions.indexOf(permission) < 0
        )
    );
  }

  addPermission(event: MatChipInputEvent) {
    const { input, value } = event;

    if (!(value || '').trim()) {
      return;
    }

    if (this.tokenPermissions.indexOf(value) <= 0) {
      return;
    }

    this.selectedPermissions.push(value.trim());

    if (input) {
      input.value = '';
    }

    this.onPermissionInput({ value: this.permissionInput.nativeElement.value });
  }

  removePermission(index: number): void {
    if (index < 0) {
      return;
    }

    this.selectedPermissions.splice(index, 1);

    this.onPermissionInput({ value: this.permissionInput.nativeElement.value });
  }

  selectPermission(event: MatAutocompleteSelectedEvent): void {
    const {
      option: { viewValue },
    } = event;

    this.selectedPermissions.push(viewValue);

    this.permissionInput.nativeElement.value = '';

    this.onPermissionInput({ value: this.permissionInput.nativeElement.value });
  }
  // #endregion Chips Permissions
}
