import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { IUser } from '../interfaces';
import { BackendService } from './backend.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authStatusSub = new ReplaySubject<boolean>(1);

  public readonly authStatus$ = this.authStatusSub.asObservable();

  private authorizedUserSub = new ReplaySubject<IUser | undefined>(1);

  public readonly authorizedUser$ = this.authorizedUserSub.asObservable();

  private allUserPermissionsSub = new ReplaySubject<string[]>(1);

  public readonly allUserPermissions$ = this.allUserPermissionsSub.asObservable();

  private allTokenPermissionsSub = new ReplaySubject<string[]>(1);

  public readonly allTokenPermissions$ = this.allTokenPermissionsSub.asObservable();

  constructor(
    private backendService: BackendService,
    private cacheService: CacheService,
    private router: Router
  ) {
    const tokenExists = this.cacheService.exists('token');
    const refreshTokenExists = this.cacheService.exists('refreshToken');
    if (tokenExists && refreshTokenExists) {
      of({})
        .pipe(
          delay(1),
          switchMap(() => this.initializeForUser())
        )
        .subscribe(() => {
          this.authStatusSub.next(true);
        });
    } else {
      this.logOut();
    }
  }

  public logIn(username: string, password: string): Observable<IUser> {
    return this.backendService
      .post<{
        token: string;
        refreshToken: string;
      }>('user/login', { username, password })
      .pipe(
        switchMap((result) => {
          if (!result || !result.token || !result.refreshToken) {
            return throwError(new Error('NotLoggedIn'));
          }

          const { token, refreshToken } = result;

          this.cacheService.set('token', token);
          this.cacheService.set('refreshToken', refreshToken);
          this.authStatusSub.next(true);

          return this.initializeForUser();
        }),
        map(({ user }) => user)
      );
  }

  public logOut(): void {
    this.cacheService.clear();
    this.authStatusSub.next(false);
    this.authorizedUserSub.next(undefined);
    this.allUserPermissionsSub.next([]);
    this.router.navigateByUrl('/login');
  }

  public refreshToken() {
    const token = this.cacheService.get<string>('token');
    const refreshToken = this.cacheService.get<string>('refreshToken');

    return this.backendService
      .post<{ token: string }>('user/refresh', {
        token,
        refreshToken,
      })
      .pipe(
        tap((result) => {
          const { token } = result;

          this.cacheService.set('token', token);
        })
      );
  }

  private initializeForUser(): Observable<{
    user: IUser;
    permissions: string[];
  }> {
    const userInfo$ = this.backendService.get<IUser>('user/auth/users/me').pipe(
      tap((user) => {
        this.authorizedUserSub.next(user);
      })
    );

    const userPermissionInfo$ = this.backendService
      .get<string[]>('user/auth/permissions/user')
      .pipe(
        catchError((error) => {
          if (error.status === 403) {
            return of([]);
          }
          return throwError(error);
        }),
        tap((permissions) => {
          this.allUserPermissionsSub.next(permissions);
        })
      );

    const tokenPermissionInfo$ = this.backendService
      .get<string[]>('user/auth/permissions/token')
      .pipe(
        catchError((error) => {
          if (error.status === 403) {
            return of([]);
          }
          return throwError(error);
        }),
        tap((permissions) => {
          this.allTokenPermissionsSub.next(permissions);
        })
      );

    return combineLatest([
      userInfo$,
      userPermissionInfo$,
      tokenPermissionInfo$,
    ]).pipe(map(([user, permissions]) => ({ user, permissions })));
  }

  public updateMe(
    body: Partial<IUser & { oldPassword: string; newPassword: string }>
  ) {
    return this.backendService.put('user/auth/users/me', body).pipe(
      switchMap((res) => this.initializeForUser()),
      map(({ user }) => user)
    );
  }
}
