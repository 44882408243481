<div class="page">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon class="page-icon">home</mat-icon>
      </div>
      <mat-card-title>CampaignSuite</mat-card-title>
      <mat-card-subtitle>Gopublic</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content></mat-card-content>
  </mat-card>
</div>
