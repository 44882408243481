import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CacheService } from './cache.service';

interface RequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  private _base: string;

  public set base(value: string) {
    let newValue = value;
    if (!value) {
      newValue = environment.apiUrl;
    }

    if (!value.startsWith('https://') && !value.startsWith('http://')) {
      newValue = `https://${value}`;
    }

    this._base = newValue;
    this.cacheService.set('base-url', newValue);
  }

  public get base(): string {
    if (this._base) {
      return this._base;
    }

    const cacheValue = this.cacheService.get<string>('base-url');

    if (!cacheValue) {
      this._base = environment.apiUrl;
    } else {
      this._base = cacheValue;
    }

    return this._base;
  }

  constructor(private http: HttpClient, private cacheService: CacheService) {}

  get<T>(
    endpoint: string,
    options: Partial<RequestOptions> = {}
  ): Observable<T> {
    const url = [this.base, endpoint].join('/');

    return this.http.get<T>(url, options);
  }

  post<T>(
    endpoint: string,
    data: any,
    options: Partial<RequestOptions> = {}
  ): Observable<T> {
    const url = [this.base, endpoint].join('/');

    return this.http.post<T>(url, data, options);
  }

  put<T>(
    endpoint: string,
    data: any,
    options: Partial<RequestOptions> = {}
  ): Observable<T> {
    const url = [this.base, endpoint].join('/');

    return this.http.put<T>(url, data, options);
  }

  del<T>(
    endpoint: string,
    options: Partial<RequestOptions> = {}
  ): Observable<T> {
    const url = [this.base, endpoint].join('/');

    return this.http.delete<T>(url, options);
  }
}
