import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IToken } from '../../interfaces';

@Component({
  selector: 'app-token-table',
  templateUrl: './token-table.component.html',
  styleUrls: ['./token-table.component.scss'],
})
export class TokenTableComponent {
  @Input() public tokens: IToken[];
  @Input() public canEdit = false;
  @Input() public canDelete = false;

  @Input() public isMobile = true;

  private editClickedEmitter: EventEmitter<IToken> = new EventEmitter();
  @Output() editClicked = this.editClickedEmitter.asObservable();

  private deleteClickedEmitter: EventEmitter<IToken> = new EventEmitter();
  @Output() deleteClicked = this.deleteClickedEmitter.asObservable();

  public displayedColumns: string[] = [
    'token',
    'status',
    'domains',
    'permissions',
    'edit',
    'delete',
  ];

  public clickEdit(token: IToken) {
    this.editClickedEmitter.next(token);
  }

  public clickDelete(token: IToken) {
    this.deleteClickedEmitter.next(token);
  }
}
