import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { CacheService } from '../services/cache.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;

  private freshTokenSubject: BehaviorSubject<
    string | undefined
  > = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    private cacheService: CacheService,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(
        req.clone({
          headers: req.headers.set(
            'Authorization',
            `Bearer ${this.cacheService.get<string>('token') || 'Anonymous'}`
          ),
        })
      )
      .pipe(
        catchError((originalError) => {
          if (originalError.status !== 401) {
            return throwError(originalError);
          }

          if (this.isRefreshing) {
            return this.freshTokenSubject.pipe(
              filter((token) => !!token),
              switchMap((token) =>
                next.handle(
                  req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      `Bearer ${token}`
                    ),
                  })
                )
              )
            );
          }

          this.isRefreshing = true;
          this.freshTokenSubject.next(undefined);

          return this.authService.refreshToken().pipe(
            catchError((refreshError: any) => {
              if (refreshError.status === 403 || refreshError.status === 400) {
                this.authService.logOut();
              }
              return throwError(refreshError);
            }),
            switchMap(({ token }) => {
              this.freshTokenSubject.next(token);
              this.isRefreshing = false;

              return next.handle(
                req.clone({
                  headers: req.headers.set('Authorization', `Bearer ${token}`),
                })
              );
            })
          );
        })
      );
  }
}
