import { OverlayContainer } from '@angular/cdk/overlay';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { CacheService } from './services/cache.service';
import { ResponsiveService } from './services/responsive.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: true }) public sideNav: MatSidenav;
  @HostBinding('class.dark-mode') darkMode = false;

  year = new Date().getUTCFullYear();

  showTopBar: boolean = false;
  dynamicMenu: boolean = false;
  menuIconObs: Observable<boolean>;
  menuItems: Array<{ label: string; route: string; icon: string }> = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private responsiveService: ResponsiveService,
    private cacheService: CacheService,
    private router: Router,
    private overlayContainer: OverlayContainer
  ) {}

  ngOnInit(): void {
    this.initTheme();
    this.initMenuIcon();
    this.checkBreakpointMenuType();
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        if (this.dynamicMenu) {
          this.sideNav.close();
        }
      });

    const authSub = combineLatest([
      this.authService.authStatus$,
      this.authService.authorizedUser$,
      this.responsiveService.isMobile$,
    ]).subscribe(([authStatus, user, isMobile]) => {
      this.showTopBar = authStatus;

      if (!this.showTopBar) {
        this.sideNav.close();
      }

      if (this.showTopBar) {
        if (!isMobile) {
          this.sideNav.open();
        }

        this.dynamicMenu = isMobile;
      }

      this.menuItems = this.getMenuItems(user?.permissions || []);
    });

    this.subscriptions.push(authSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private initTheme() {
    if (this.cacheService.exists('theme')) {
      const theme = this.cacheService.get<'dark' | 'light'>('theme');
      this.useDarkTheme(theme === 'dark');
    } else {
      this.useDarkTheme(this.responsiveService.isSystemDarkMode());
    }
  }

  private initMenuIcon() {
    this.menuIconObs = merge(
      of(false), // First init
      this.sideNav.openedStart.pipe(map(() => true)),
      this.sideNav.closedStart.pipe(map(() => false))
    );
  }

  private checkBreakpointMenuType() {
    const breakpointSub = this.responsiveService.isMobile$.subscribe(
      (isMobile) => {
        this.dynamicMenu = isMobile;

        if (!this.showTopBar) {
          return;
        }

        if (!this.dynamicMenu) {
          this.sideNav.open();
        } else {
          this.sideNav.close();
        }
      }
    );

    this.subscriptions.push(breakpointSub);
  }

  private getMenuItems(
    permissions: string[]
  ): Array<{ label: string; route: string; icon: string }> {
    const lowerCasedPermissions = [
      ...permissions.filter((p) => !!p).map((p) => p.toLowerCase()),
    ];
    const result: Array<{ label: string; route: string; icon: string }> = [];
    result.push({ label: 'Home', route: '/', icon: 'home' });

    if (lowerCasedPermissions.includes('viewusers')) {
      result.push({ label: 'Users', route: '/users', icon: 'people_alt' });
    }

    result.push({ label: 'Account', route: '/account', icon: 'person' });

    return result;
  }

  public toggleMenu() {
    if (!this.showTopBar) {
      this.sideNav.close();
      return;
    }

    this.sideNav.toggle();
  }

  public useDarkTheme(val: boolean) {
    const theme: 'dark' | 'light' = val ? 'dark' : 'light';
    this.cacheService.set('theme', theme);
    this.darkMode = val;
    if (val) {
      this.overlayContainer.getContainerElement().classList.add('dark-mode');
    } else {
      this.overlayContainer.getContainerElement().classList.remove('dark-mode');
    }
  }

  public toggleTheme() {
    this.useDarkTheme(!this.darkMode);
  }

  public logOut() {
    this.authService.logOut();
  }
}
