import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TokenEditComponent } from '../../components/token-edit/token-edit.component';
import { IToken, IUser } from '../../interfaces';
import { AuthService } from '../../services/auth.service';
import { ResponsiveService } from '../../services/responsive.service';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
})
export class AccountPage implements OnInit, OnDestroy {
  public user: IUser;
  public tokens: IToken[];
  public isMobile: boolean;

  public canCreateTokens: boolean = false;
  public canEditTokens: boolean = false;
  public canDeleteTokens: boolean = false;

  public informationForm: FormGroup;
  public informationCallSend: boolean = false;

  public permissionForm: FormGroup;
  public permissionCallSend: boolean = false;

  public permissionFormKeys: string[];

  private subscriptions: Subscription[] = [];
  private tokenPermissions: string[];

  @ViewChild('deleteModal', { static: true })
  private deleteModal: TemplateRef<any>;

  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private responsiveService: ResponsiveService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const tableSub = this.responsiveService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    const initSub = combineLatest([
      this.authService.authorizedUser$,
      this.authService.allUserPermissions$,
      this.tokenService.getTokensForUser('me'),
      this.authService.allTokenPermissions$,
    ]).subscribe(([user, userPermissions, tokens, tokenPermissions]) => {
      if (!user) {
        return;
      }

      this.user = user;
      this.tokens = tokens;
      this.tokenPermissions = [...tokenPermissions];

      this.initInformationForm(user);

      const lowerCasedUserPermissions = user.permissions.map((p) =>
        p.toLowerCase()
      );

      this.canCreateTokens = lowerCasedUserPermissions.includes(
        'generateowntokens'
      );

      this.canEditTokens = lowerCasedUserPermissions.includes('editowntokens');
      this.canDeleteTokens = lowerCasedUserPermissions.includes(
        'deleteowntokens'
      );

      this.initPermissionForm(userPermissions, lowerCasedUserPermissions);
    });

    this.subscriptions.push(initSub, tableSub);
  }

  onSubmitInformationForm() {
    this.informationForm.markAllAsTouched();
    this.informationForm.updateValueAndValidity();
    if (!this.informationForm.value || this.informationForm.invalid) {
      return;
    }

    const {
      value: { company, oldPassword, newPassword, username },
    } = this.informationForm;

    if (!company) {
      return;
    }

    const body: Partial<IUser> &
      Partial<{ newPassword: string; oldPassword: string }> = {
      company,
      username,
    };

    let passwordChange = false;

    if (oldPassword || newPassword) {
      // at least 1 is filled. Check if both are filled
      if (!(oldPassword && newPassword)) {
        // Only 1 is filled
        return;
      }

      passwordChange = true;
      body.newPassword = newPassword;
      body.oldPassword = oldPassword;
    }

    this.informationCallSend = true;
    this.updateMe(body, 'information', passwordChange);
  }

  onSubmitPermissionForm() {
    if (!this.permissionForm.value || this.permissionForm.invalid) {
      return;
    }

    const newPermissions: string[] = Object.keys(
      this.permissionForm.value
    ).filter((key) => !!this.permissionForm.value[key]);

    this.permissionCallSend = true;
    this.updateMe({ permissions: newPermissions }, 'permission');
  }

  private initInformationForm(user: IUser) {
    this.informationForm = new FormGroup(
      {
        id: new FormControl({ value: user._id, disabled: true }),
        username: new FormControl(user.username, [
          Validators.required,
          Validators.email,
        ]),
        company: new FormControl(user.company, [Validators.required]),
        oldPassword: new FormControl(undefined),
        newPassword: new FormControl(undefined),
      },
      (group: AbstractControl) => {
        const oldPasswordInput = (group as FormGroup).controls['oldPassword'];
        const newPasswordInput = (group as FormGroup).controls['newPassword'];

        if (!oldPasswordInput.value && !newPasswordInput.value) {
          return null;
        }

        if (oldPasswordInput.value && newPasswordInput.value) {
          return null;
        }

        if (oldPasswordInput.untouched || newPasswordInput.untouched) {
          return null;
        }

        return {
          bothPasswordFieldRequired: true,
        };
      }
    );
  }

  private initPermissionForm(
    allPermissions: string[],
    userPermissions: string[]
  ) {
    this.permissionFormKeys = [];

    if (!userPermissions.includes('changeownpermissions')) {
      return;
    }

    const form: { [key: string]: FormControl } = {};

    allPermissions.forEach((permission) => {
      form[permission] = new FormControl(
        userPermissions.includes(permission.toLowerCase())
      );
      this.permissionFormKeys.push(permission);
    });

    this.permissionForm = new FormGroup(form);
  }

  private updateMe(
    body: Partial<IUser & { oldPassword: string; newPassword: string }>,
    form: 'information' | 'permission',
    isPasswordChange: boolean = false
  ) {
    this.authService.updateMe(body).subscribe(
      (user) => {
        if (this.informationForm) {
          if (this.informationForm.controls.oldPassword) {
            this.informationForm.controls.oldPassword.reset();
          }

          if (this.informationForm.controls.newPassword) {
            this.informationForm.controls.newPassword.reset();
          }
        }
        this.snackBar.open('Account Updated', undefined, {
          panelClass: ['success'],
          verticalPosition: 'top',
        });

        this[
          form === 'information' ? 'informationCallSend' : 'permissionCallSend'
        ] = false;
      },
      (error) => {
        if (isPasswordChange && error.status === 403) {
          this.snackBar.open('Incorrect password', 'OK', {
            panelClass: ['error'],
            verticalPosition: 'top',
          });
        }
        if (error.status === 409) {
          this.snackBar.open('Email already in use', 'OK', {
            panelClass: ['error'],
            verticalPosition: 'top',
          });
        }
        this[
          form === 'information' ? 'informationCallSend' : 'permissionCallSend'
        ] = false;
      }
    );
  }

  public editToken(token: IToken) {
    this.dialog
      .open(TokenEditComponent, {
        width: this.isMobile ? '75vw' : '50vw',
        data: {
          token: { ...token },
          user: 'me',
          tokenPermissions: this.tokenPermissions,
        },
      })
      .afterClosed()
      .subscribe((result: IToken) => {
        if (result) {
          this.tokens = [
            ...this.tokens.map((t) => {
              if (t._id === result._id) {
                return result;
              }
              return t;
            }),
          ];
        }
      });
  }

  public deleteToken(token: IToken) {
    this.dialog
      .open(this.deleteModal, {
        width: this.isMobile ? '75vw' : '50vw',
        data: {
          token: token.token,
          domains: token.domains,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result: boolean) => {
          if (result) {
            return this.tokenService.deleteToken('me', token._id);
          }
          return of(undefined);
        })
      )
      .subscribe(
        (result) => {
          if (result) {
            this.tokens = [...this.tokens.filter((t) => t._id !== token._id)];
          }
        },
        () => {
          this.snackBar.open('Something went wrong...', 'OK', {
            panelClass: ['error'],
            verticalPosition: 'top',
          });
        }
      );
  }

  public addToken() {
    this.dialog
      .open(TokenEditComponent, {
        width: this.isMobile ? '75vw' : '50vw',
        data: {
          user: 'me',
          tokenPermissions: this.tokenPermissions,
        },
      })
      .afterClosed()
      .subscribe((result: IToken) => {
        if (result) {
          this.tokens = [...this.tokens, result];
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
