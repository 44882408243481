import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { IUser } from '../../interfaces';
import { AuthService } from '../../services/auth.service';
import { ResponsiveService } from '../../services/responsive.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
})
export class UsersPage implements OnInit, OnDestroy {
  public allPermissions: string[] = [];
  private userPermissions: string[] = []; // Already lowercased

  @ViewChild('addUserModal', { static: true })
  private addUserModal: TemplateRef<any>;
  public canCreateUsers: boolean = false;
  public canGiveNewUsersPermissions: boolean = false;
  public createUserForm: FormGroup | undefined;
  public addUserCallSent: boolean = false;

  @ViewChild('permissionInput', { static: false })
  permissionInput: ElementRef<HTMLInputElement>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public filteredPermissions = new BehaviorSubject<string[]>(
    this.allPermissions
  );
  public selectedPermissions: string[] = [];

  public paginatorSettings: {
    length: number;
    pageIndex: number;
    pageSize: number;
    pageSizeOptions: number[];
  } = {
    length: 0,
    pageIndex: 1,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  };

  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef<HTMLInputElement>;
  public searchValue = '';

  public isMobile: boolean = true;
  public users: IUser[] = [];
  public displayedColumns: string[] = [
    '_id',
    'username',
    'company',
    'tokenLimit',
  ];

  private subscriptions: Subscription[] = [];

  private refreshUsersObs = new BehaviorSubject<void>(undefined);

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    const paramObs$ = this.activatedRoute.queryParams.pipe(
      map((params) => {
        const { s, per_page, page } = params;

        const result: Partial<{ s: string; per_page: number; page: number }> = {
          s,
        };

        if (page && !Number.isNaN(+page)) {
          result.page = +page;
        }

        if (per_page && !Number.isNaN(+per_page)) {
          result.per_page = +per_page;
        }

        return result;
      })
    );

    const authObs$ = this.authService.authorizedUser$.pipe(
      switchMap((user) => {
        this.userPermissions = [
          ...(user?.permissions
            .filter((p) => !!p)
            .map((p) => p.toLowerCase()) || []),
        ];

        return combineLatest([
          of({
            viewUsers: this.userPermissions.includes('viewusers'),
            createUsers: this.userPermissions.includes('createusers'),
          }),
          this.authService.allUserPermissions$,
        ]);
      })
    );

    const initSub = combineLatest([paramObs$, authObs$, this.refreshUsersObs])
      .pipe(
        switchMap(([params, [{ viewUsers, createUsers }, allPermissions]]) => {
          if (!viewUsers) {
            return throwError('notAllowed');
          }

          this.canCreateUsers = createUsers;
          this.allPermissions = allPermissions;
          this.canGiveNewUsersPermissions = allPermissions.length > 0;

          const { s, page, per_page } = params;
          this.paginatorSettings = {
            ...this.paginatorSettings,
            pageSize: per_page || 10,
            pageIndex: page ? page - 1 : 0,
          };
          if (!this.searchValue) {
            this.searchValue = s || '';
          }
          return this.userService.getUsers(s, page, per_page);
        })
      )
      .subscribe(
        (result) => {
          this.users = result.users;
          this.paginatorSettings = {
            ...this.paginatorSettings,
            length: result.count,
          };
        },
        (error) => {
          if (error === 'notAllowed') {
            this.router.navigate(['/']);
          }
        }
      );

    const breakpointSub = this.responsiveService.isMobile$.subscribe(
      (isMobile) => {
        this.isMobile = isMobile;
      }
    );
    const searchSub = fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        map((event: any) => event.target.value),
        filter(
          (searchQuery) => searchQuery.length >= 2 || searchQuery.length === 0
        ),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((searchQuery) => {
        this.onSearchChange(searchQuery);
      });

    this.subscriptions.push(initSub, breakpointSub, searchSub);
  }

  onPaginatorChange(event: PageEvent) {
    const { pageIndex, pageSize } = event;

    const pageToShow = pageIndex + 1;

    this.router.navigate([], {
      queryParams: { page: pageToShow, per_page: pageSize },
      queryParamsHandling: 'merge',
    });
  }

  onSearchChange(value: string) {
    this.router.navigate([], {
      queryParams: { s: value.length > 0 ? value : undefined, page: 1 },
      queryParamsHandling: 'merge',
    });
  }

  resetSearch() {
    this.searchValue = '';

    this.router.navigate([], {
      queryParams: { s: undefined },
      queryParamsHandling: 'merge',
    });
  }

  clickUser(id: string) {
    this.router.navigate(['/', 'users', id]);
  }

  addUserButton() {
    this.createNewUserForm();
    this.dialog.open(this.addUserModal, {
      width: this.isMobile ? '75vw' : '50vw',
    });
  }

  onUserAdd() {
    if (!this.createUserForm) {
      return;
    }

    if (this.createUserForm.invalid) {
      return;
    }

    const { username, password, company } = this.createUserForm.value;

    const newUser: {
      username: string;
      password?: string;
      company: string;
      permissions?: string[];
    } = { username, password, company };

    if (this.canGiveNewUsersPermissions) {
      newUser.permissions = this.selectedPermissions;
    }

    this.addUserCallSent = true;

    this.userService.register(newUser).subscribe(
      () => {
        this.closeDialog();
        this.selectedPermissions = [];
        this.addUserCallSent = false;
        this.refreshUsersObs.next();
      },
      (error) => {
        this.addUserCallSent = false;
      }
    );
  }

  // Has to be for the event in the HTML :/
  onPermissionInput(target: any) {
    this.filteredPermissions.next(
      this.allPermissions.filter(
        (permission) => permission.toLowerCase().indexOf(target.value) === 0
      )
    );
  }

  addPermission(event: MatChipInputEvent) {
    const { input, value } = event;

    if (!(value || '').trim()) {
      return;
    }

    if (this.allPermissions.indexOf(value) <= 0) {
      return;
    }

    this.selectedPermissions.push(value.trim());

    if (input) {
      input.value = '';
    }

    this.onPermissionInput({ value: this.permissionInput.nativeElement.value });
  }

  removePermission(index: number): void {
    if (index < 0) {
      return;
    }

    this.selectedPermissions.splice(index, 1);

    this.onPermissionInput({ value: this.permissionInput.nativeElement.value });
  }

  selectPermission(event: MatAutocompleteSelectedEvent): void {
    const {
      option: { viewValue },
    } = event;

    this.selectedPermissions.push(viewValue);

    this.permissionInput.nativeElement.value = '';

    this.onPermissionInput({ value: this.permissionInput.nativeElement.value });
  }

  closeDialog() {
    this.dialog.closeAll();
    this.createUserForm = undefined;
  }

  private createNewUserForm() {
    this.createUserForm = new FormGroup({
      username: new FormControl(undefined, [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl(undefined),
      company: new FormControl(undefined, [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
