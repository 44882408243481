import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  private isMobileSub = new ReplaySubject<boolean>();
  public isMobile$ = this.isMobileSub.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    const responsiveCheck = '(max-width: 599px)';
    breakpointObserver.observe(responsiveCheck).subscribe((result) => {
      this.isMobileSub.next(result.matches);
    });
    this.isMobileSub.next(this.breakpointObserver.isMatched(responsiveCheck));
  }

  public isSystemDarkMode() {
    return this.breakpointObserver.isMatched('(prefers-color-scheme: dark)');
  }
}
