<mat-toolbar *ngIf="showTopBar" color="primary">
  <button
    *ngIf="dynamicMenu"
    mat-icon-button
    aria-label="Toggle menu"
    (click)="this.toggleMenu()"
  >
    <mat-icon>{{ (menuIconObs | async) ? "close" : "menu" }}</mat-icon>
  </button>
  <span>CampaignSuite</span>
  <span class="spacer"></span>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
</mat-toolbar>
<mat-sidenav-container [autosize]="false">
  <mat-sidenav
    #sidenav
    [mode]="dynamicMenu ? 'push' : 'side'"
    [disableClose]="!dynamicMenu"
  >
    <mat-nav-list>
      <a
        mat-list-item
        *ngFor="let item of menuItems"
        class="menu-item"
        [routerLink]="item.route"
        routerLinkActive="is-active"
        [routerLinkActiveOptions]="{
          exact: item.route === '/' || item.route === ''
        }"
        matLine
      >
        <mat-icon>{{ item.icon }}</mat-icon>
        {{ item.label }}
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{ 'has-bars': showTopBar }">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<mat-toolbar *ngIf="showTopBar" color="primary" class="bottom-bar">
  <span class="mat-small">{{ year }}</span>
  <span class="spacer"></span>
  <span class="mat-small d-flex align-center"
    ><mat-icon>copyright</mat-icon> Gopublic</span
  >
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="this.logOut()">
    <mat-icon>exit_to_app</mat-icon>
    <span>Log out</span>
  </button>
  <button mat-menu-item (click)="this.toggleTheme()">
    <mat-icon>{{ !darkMode ? "dark_mode" : "light_mode" }}</mat-icon>
    <span>Switch theme</span>
  </button>
</mat-menu>
