<mat-table [dataSource]="tokens" *ngIf="!isMobile">
  <ng-container matColumnDef="token">
    <mat-header-cell *matHeaderCellDef class="token-cell">
      <span class="mat-h3 margin-0">Token</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="token-cell">
      {{ row.token }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status" class="status-cell">
    <mat-header-cell *matHeaderCellDef>
      <span class="mat-h3 margin-0">Status</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="status-cell">
      {{ row.status }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="domains">
    <mat-header-cell *matHeaderCellDef class="domains-cell">
      <span class="mat-h3 margin-0">Domains</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="domains-cell">
      {{ row.domains.join(", ") }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="permissions">
    <mat-header-cell *matHeaderCellDef class="permissions-cell">
      <span class="mat-h3 margin-0">Permissions</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="permissions-cell">
      {{ row.permissions.join(", ") }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="edit">
    <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
    <mat-cell *matCellDef="let row" class="icon-cell">
      <button
        mat-icon-button
        color="primary"
        [disabled]="!canEdit"
        (click)="clickEdit(row)"
      >
        <mat-icon>create</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="delete">
    <mat-header-cell *matHeaderCellDef class="icon-cell"></mat-header-cell>
    <mat-cell *matCellDef="let row" class="icon-cell">
      <button
        mat-icon-button
        color="warn"
        [disabled]="!canDelete"
        (click)="clickDelete(row)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="table-row"
  ></mat-row>
  <div *matNoDataRow class="no-data">No tokens found</div>
</mat-table>
<ng-container *ngIf="isMobile">
  <mat-list>
    <mat-list-item *ngFor="let token of tokens" class="table-row" matRipple>
      <div mat-line>{{ token.token }}</div>
      <div
        mat-line
        [ngClass]="{
          'txt-primary': token.status === 'active',
          'txt-secondary': token.status === 'inactive'
        }"
      >
        {{ token.status }}
      </div>
      <div mat-line class="id-line">{{ token.domains.join(", ") }}</div>
      <button
        mat-icon-button
        color="primary"
        *ngIf="canEdit"
        (click)="clickEdit(token)"
      >
        <mat-icon>create</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        *ngIf="canDelete"
        (click)="clickDelete(token)"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</ng-container>
