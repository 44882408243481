import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AccountPage } from './pages/account/account.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { LoginPage } from './pages/login/login.page';
import { UserDetailPage } from './pages/user-detail/user-detail.page';
import { UsersPage } from './pages/users/users.page';
import { TokenResolver } from './resolvers/token.resolver';
import { UserResolver } from './resolvers/user.resolver';

const routes: Routes = [
  {
    path: '',
    component: DashboardPage,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginPage,
  },
  {
    path: 'account',
    component: AccountPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id',
        component: UserDetailPage,
        resolve: {
          user: UserResolver,
          tokens: TokenResolver,
        },
      },
      { path: '', component: UsersPage },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
