import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

interface CacheOptions {
  expires?: number;
  maxAge?: number;
}

interface StorageObject {
  value: any;
  options: CacheOptions;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  public prefix = 'campaignsuite';

  private readonly defaultOptions: CacheOptions = {
    expires: Number.MAX_VALUE,
    maxAge: Number.MAX_VALUE,
  };

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  public exists(key: string, storageType?: 'local' | 'session'): boolean {
    return !!this.get<any>(key, storageType);
  }

  public get<C>(key: string, storageType?: 'local' | 'session'): C | undefined {
    if (isPlatformServer(this.platformId)) {
      return undefined;
    }

    const storage = storageType === 'session' ? sessionStorage : localStorage;

    const storageItem = storage.getItem(this.toStorageKey(key));

    if (!storageItem) {
      return undefined;
    }

    const storageValue = JSON.parse(storageItem);
    if (!CacheService.validateStorageValue(storageValue)) {
      this.remove(key);
      return undefined;
    }

    return storageValue.value as C;
  }

  public set(
    key: string,
    value: any,
    storageType: 'local' | 'session' = 'local',
    options: CacheOptions = this.defaultOptions
  ) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const storage = storageType === 'session' ? sessionStorage : localStorage;

    return storage.setItem(
      this.toStorageKey(key),
      JSON.stringify(this.toStorageValue(value, options))
    );
  }

  public remove(key: string, storageType?: 'local' | 'session'): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const storage = storageType === 'session' ? sessionStorage : localStorage;
    storage.removeItem(this.toStorageKey(key));
  }

  public clear(storageType?: 'local' | 'session'): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (!storageType) {
      sessionStorage.clear();
      localStorage.clear();
      return;
    }

    const storage = storageType === 'session' ? sessionStorage : localStorage;
    return storage.clear();
  }

  private toStorageKey(key: string): string {
    return `${this.prefix}-${key}`;
  }

  private toStorageValue(value: any, options: CacheOptions): StorageObject {
    const storageOptions: CacheOptions = {};

    if (options.expires) {
      storageOptions.expires = options.expires;
    } else if (options.maxAge) {
      storageOptions.expires = Date.now() + options.maxAge * 1000;
    } else {
      storageOptions.expires = this.defaultOptions.expires;
    }

    storageOptions.maxAge = options.maxAge
      ? options.maxAge
      : this.defaultOptions.maxAge;

    return {
      value,
      options: storageOptions,
    };
  }

  private static validateStorageValue(value: StorageObject): boolean {
    return !!value.options.expires && value.options.expires > Date.now();
  }
}
