import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend.service';
import { AuthService } from '../../services/auth.service';
import { CacheService } from '../../services/cache.service';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  public form = new FormGroup({
    username: new FormControl(undefined, [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl(undefined, [Validators.required]),
  });

  passwordHidden = true;

  isLoading = false;

  showError = false;

  private isMobile = true;

  @ViewChild('advancedModal', { static: true })
  private advancedModal: TemplateRef<any>;

  @ViewChild('baseURLInput', { static: false })
  baseURLInput: ElementRef<HTMLInputElement>;

  constructor(
    private cacheService: CacheService,
    private authService: AuthService,
    private backendService: BackendService,
    private responsiveService: ResponsiveService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.authStatus$.pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.router.navigate(['/']);
      }
    });

    this.responsiveService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  public togglePasswordHidden() {
    this.passwordHidden = !this.passwordHidden;
  }

  public onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const {
      username,
      password,
    }: { username: string; password: string } = this.form.value;

    this.isLoading = true;
    this.showError = false;
    this.authService.logIn(username, password).subscribe(
      () => {
        const url =
          this.cacheService.get<string>('redirect-url', 'session') || '/';

        this.cacheService.remove('redirect-url', 'session');

        this.router.navigate([url]);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        if (error.status === 403) {
          this.showError = true;
        }
      }
    );
  }

  openAdvanced() {
    this.dialog
      .open(this.advancedModal, {
        width: this.isMobile ? '80vw' : '30vw',
      })
      .afterOpened()
      .subscribe(() => {
        this.baseURLInput.nativeElement.value = this.backendService.base;
      });
  }

  cancelAdvanced() {
    this.dialog.closeAll();
  }

  saveAdvanced() {
    this.backendService.base = this.baseURLInput.nativeElement.value;
    this.dialog.closeAll();
  }
}
