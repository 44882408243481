import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IToken } from '../interfaces';
import { BackendService } from './backend.service';

@Injectable({ providedIn: 'root' })
export class TokenService {
  constructor(private backendService: BackendService) {}

  getTokensForUser(id: string) {
    return this.backendService.get<IToken[]>(`user/auth/users/${id}/tokens`);
  }

  updateToken(user: string, id: string, body: Partial<IToken>) {
    return this.backendService.put<IToken>(
      `user/auth/users/${user}/tokens/${id}`,
      body
    );
  }

  createToken(user: string, body: Partial<IToken>) {
    return this.backendService.post<IToken>(
      `user/auth/users/${user}/tokens`,
      body
    );
  }

  deleteToken(user: string, id: string) {
    return this.backendService
      .del<string>(`user/auth/users/${user}/tokens/${id}`)
      .pipe(map(() => 'deleted'));
  }
}
