import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { IUser } from '../interfaces';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<IUser | undefined> {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IUser | undefined> {
    const id = route.paramMap.get('id');

    return this.authService.authorizedUser$.pipe(
      take(1),
      switchMap((user) => {
        if (user?._id === id) {
          this.router.navigate(['/', 'account']);
          return of(undefined);
        }

        return this.userService.getUser(id!);
      }),
      catchError(() =>
        this.snackbar
          .open('Back to users', 'OK', {
            verticalPosition: 'top',
            panelClass: 'error',
          })
          .afterDismissed()
          .pipe(
            map(() => {
              this.router.navigate(['/', 'users']);
              return undefined;
            })
          )
      )
    );
  }
}
