<div class="page" *ngIf="user">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon class="page-icon">person</mat-icon>
      </div>
      <mat-card-title>{{ user.username }}</mat-card-title>
      <mat-card-subtitle>{{ user.company }}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <div class="info-container">
    <div class="info-item">
      <mat-card *ngIf="informationForm">
        <mat-card-header>
          <mat-card-title>Account information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form
            (ngSubmit)="onSubmitInformationForm()"
            [formGroup]="informationForm"
          >
            <div class="form-field">
              <mat-form-field appearance="legacy">
                <mat-label>Id</mat-label>
                <input
                  matInput
                  name="Id"
                  placeholder="123456789"
                  formControlName="id"
                  type="text"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <mat-form-field appearance="legacy">
                <mat-label>Username</mat-label>
                <input
                  matInput
                  name="username"
                  placeholder="info@campaignsuite.com"
                  formControlName="username"
                  required
                  type="email"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <mat-form-field appearance="legacy">
                <mat-label>Company</mat-label>
                <input
                  matInput
                  name="company"
                  placeholder="Gopublic"
                  formControlName="company"
                  required
                  type="text"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <mat-form-field appearance="legacy">
                <mat-label>Old password</mat-label>
                <input
                  matInput
                  name="old-password"
                  placeholder="password"
                  formControlName="oldPassword"
                  type="password"
                />
              </mat-form-field>
            </div>
            <div class="form-field">
              <mat-form-field appearance="legacy">
                <mat-label>New password</mat-label>
                <input
                  matInput
                  name="new-password"
                  placeholder="password"
                  formControlName="newPassword"
                  type="password"
                />
              </mat-form-field>
            </div>
            <ng-container
              *ngIf="informationForm.errors && informationForm.errors.bothPasswordFieldRequired"
            >
              <mat-error> Both password fields should be filled </mat-error>
            </ng-container>
            <ng-container
              *ngTemplateOutlet="formButtons; context: {valid: informationForm.valid && !informationCallSend}"
            >
            </ng-container>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="info-item">
      <mat-card *ngIf="user">
        <mat-card-header>
          <mat-card-title>
            Permission information
            <span
              *ngIf="permissionForm"
              class="mat-small"
              matTooltip="Scrollable list"
              matTooltipPosition="after"
            >
              <mat-icon class="small"> help </mat-icon>
            </span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngIf="permissionForm">
            <form
              (ngSubmit)="onSubmitPermissionForm()"
              [formGroup]="permissionForm"
            >
              <div class="permission-form">
                <div
                  class="form-field permission"
                  *ngFor="let formField of permissionFormKeys"
                >
                  <mat-checkbox color="primary" [formControlName]="formField"
                    >{{formField}}</mat-checkbox
                  >
                </div>
              </div>
              <ng-container
                *ngTemplateOutlet="formButtons; context: {valid: permissionForm.valid && !permissionCallSend}"
              >
              </ng-container>
            </form>
          </ng-container>
          <ng-container *ngIf="!permissionForm">
            <mat-list dense>
              <mat-list-item *ngFor="let permission of user.permissions">
                <mat-icon class="permission-icon" color="primary"
                  >check_circle</mat-icon
                >
                <div>{{permission}}</div>
              </mat-list-item>
            </mat-list>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="info-container">
    <div class="info-item">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon class="page-icon">vpn_key</mat-icon>
          </div>
          <mat-card-title-group>
            <mat-card-title>Tokens</mat-card-title>
            <mat-card-subtitle>
              All the users tokens ({{tokens.length}} of {{user.tokenLimit}}
              tokens created)
            </mat-card-subtitle>
          </mat-card-title-group>
          <span class="spacer"></span>
          <div
            class="button-container card-header"
            [ngClass]="{'padding-0': isMobile}"
          >
            <button
              mat-raised-button
              color="primary"
              type="button"
              *ngIf="loggedInRights.generateTokens && !isMobile"
              [disabled]="user.tokenLimit <= tokens.length"
              (click)="addToken()"
            >
              <mat-icon>create</mat-icon> Add token
            </button>
            <button
              mat-fab
              color="primary"
              type="button"
              *ngIf="loggedInRights.generateTokens && isMobile"
              [disabled]="user.tokenLimit <= tokens.length"
              (click)="addToken()"
            >
              <mat-icon>create</mat-icon>
            </button>
          </div>
        </mat-card-header>
        <mat-card-content>
          <app-token-table
            [tokens]="tokens"
            [canEdit]="loggedInRights.editTokens"
            [canDelete]="loggedInRights.deleteTokens"
            [isMobile]="isMobile"
            (editClicked)="editToken($event)"
            (deleteClicked)="deleteToken($event)"
          ></app-token-table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #formButtons let-valid="valid">
  <div class="button-container">
    <span>
      <!-- Placeholder for more buttons -->
    </span>
    <button mat-raised-button color="primary" type="submit" [disabled]="!valid">
      <mat-icon>save</mat-icon> Save
    </button>
  </div>
</ng-template>

<ng-template #deleteModal let-data>
  <h2 mat-dialog-title>Delete token</h2>
  <mat-dialog-content class="mat-typography">
    Are you sure you want to delete token {{data.token}} (Used on
    {{data.domains.length ? data.domains.join(', ') : 'no domains'}})?
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="warn">
      Delete
    </button>
  </mat-dialog-actions>
</ng-template>
