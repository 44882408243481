<div class="page">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon class="page-icon">groups</mat-icon>
      </div>
      <mat-card-title-group>
        <mat-card-title>Users</mat-card-title>
        <mat-card-subtitle>All the registered users</mat-card-subtitle>
      </mat-card-title-group>
      <span class="spacer"></span>
      <div
        class="button-container card-header"
        [ngClass]="{'padding-0': isMobile}"
      >
        <button
          mat-raised-button
          color="primary"
          type="button"
          *ngIf="canCreateUsers && !isMobile"
          (click)="addUserButton()"
        >
          <mat-icon>person_add</mat-icon> Add user
        </button>
        <button
          mat-fab
          color="primary"
          type="button"
          *ngIf="canCreateUsers && isMobile"
          (click)="addUserButton()"
        >
          <mat-icon>person_add</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="example-form-field">
        <mat-label>Search</mat-label>
        <input #searchInput matInput type="text" [(ngModel)]="searchValue" />
        <button
          mat-button
          matPrefix
          mat-icon-button
          aria-label="Search"
          (click)="searchInput.focus()"
        >
          <mat-icon [style.fontSize]="'1.5em'"> search </mat-icon>
        </button>
        <button
          mat-button
          *ngIf="searchInput && searchInput.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          (click)="resetSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <ng-container *ngIf="!isMobile">
        <table mat-table [dataSource]="users">
          <ng-container matColumnDef="_id">
            <th mat-header-cell *matHeaderCellDef colspan="2">
              <span class="mat-h3 margin-0">ID</span>
            </th>
            <td mat-cell *matCellDef="let element" colspan="2">
              {{element._id}}
            </td>
          </ng-container>
          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef colspan="2">
              <span class="mat-h3 margin-0">Username/Email</span>
            </th>
            <td mat-cell *matCellDef="let element" colspan="2">
              {{element.username}}
            </td>
          </ng-container>
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef colspan="2">
              <span class="mat-h3 margin-0">Company</span>
            </th>
            <td mat-cell *matCellDef="let element" colspan="2">
              {{element.company}}
            </td>
          </ng-container>
          <ng-container matColumnDef="tokenLimit">
            <th mat-header-cell *matHeaderCellDef colspan="1">
              <span class="mat-h3 margin-0">Token limit</span>
            </th>
            <td mat-cell *matCellDef="let element" colspan="1">
              {{element.tokenLimit}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns;"
            (click)="clickUser(row._id)"
            class="table-row"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">No data</td>
          </tr>
        </table>
      </ng-container>
      <ng-container *ngIf="isMobile">
        <mat-list>
          <mat-list-item
            *ngFor="let user of users"
            class="table-row"
            (click)="clickUser(user._id)"
            matRipple
          >
            <mat-icon mat-list-icon>person</mat-icon>
            <div mat-line>{{user.username}}</div>
            <div mat-line>
              {{user.company}}
              <span class="txt-primary"
                >(Token limit: {{ user.tokenLimit }})</span
              >
            </div>
            <div mat-line class="id-line">{{user._id}}</div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </mat-card-content>
    <mat-card-footer>
      <mat-paginator
        [length]="paginatorSettings.length"
        [pageSize]="paginatorSettings.pageSize"
        [pageSizeOptions]="paginatorSettings.pageSizeOptions"
        [pageIndex]="paginatorSettings.pageIndex"
        [showFirstLastButtons]="true"
        (page)="onPaginatorChange($event)"
      >
      </mat-paginator>
    </mat-card-footer>
  </mat-card>
</div>

<ng-template #addUserModal>
  <form
    *ngIf="createUserForm"
    [formGroup]="createUserForm"
    (ngSubmit)="onUserAdd()"
  >
    <h2 mat-dialog-title>Add a user</h2>
    <mat-dialog-content class="mat-typography">
      <div class="form-field">
        <mat-form-field appearance="legacy">
          <mat-label>Username</mat-label>
          <input
            matInput
            name="username"
            placeholder="info@campaignsuite.com"
            formControlName="username"
            required
            type="email"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field appearance="legacy">
          <mat-label>Password</mat-label>
          <input
            matInput
            name="password"
            placeholder="password"
            formControlName="password"
            type="password"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field appearance="legacy">
          <mat-label>Company</mat-label>
          <input
            matInput
            name="company"
            placeholder="Gopublic"
            formControlName="company"
            required
            type="text"
          />
        </mat-form-field>
      </div>

      <div class="form-field">
        <mat-label>Permissions</mat-label>
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let permission of selectedPermissions; let i = index"
            [selectable]="true"
            [removable]="true"
            (removed)="removePermission(i)"
          >
            {{permission}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="User permissions"
            #permissionInput
            (input)="onPermissionInput($event.target)"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addPermission($event)"
          />
        </mat-chip-list>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectPermission($event)"
        >
          <mat-option
            *ngFor="let permission of filteredPermissions | async"
            [value]="permission"
          >
            {{permission}}
          </mat-option>
        </mat-autocomplete>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        mat-button
        type="button"
        [disabled]="addUserCallSent"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        type="submit"
        [disabled]="addUserCallSent || createUserForm.invalid"
        cdkFocusInitial
      >
        Add user
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
