import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { IToken } from '../interfaces';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';

@Injectable({ providedIn: 'root' })
export class TokenResolver implements Resolve<IToken[]> {
  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IToken[]> {
    const id = route.paramMap.get('id');

    return this.authService.authorizedUser$.pipe(
      take(1),
      switchMap((user) => {
        if (user?._id === id) {
          this.router.navigate(['/', 'account']);
          return of([]);
        }

        return this.tokenService.getTokensForUser(id!);
      }),
      catchError((error) => {
        if (error?.status === 404) {
          return of([]);
        }

        return this.snackbar
          .open('Back to Users', 'OK', {
            verticalPosition: 'top',
            panelClass: 'error',
          })
          .afterDismissed()
          .pipe(
            map(() => {
              this.router.navigate(['/', 'Tokens']);
              return [];
            })
          );
      })
    );
  }
}
